import React, { useEffect, useRef } from 'react';
import './AppSection.css';

const AppSection = ({ id }) => {
  const features = [
    "AI-powered personal assistant",
    "Voice recognition and natural language processing",
    "Customizable user interface",
    "Seamless integration with other apps",
    "Real-time data analysis and insights",
    "End-to-end encryption for data privacy"
  ];

  const phoneRef = useRef(null);
  const featureListRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      { threshold: 0.1 }
    );

    const phoneElement = phoneRef.current;
    const featureListElement = featureListRef.current;
    const buttonElement = buttonRef.current;

    if (phoneElement) observer.observe(phoneElement);
    if (featureListElement) observer.observe(featureListElement);
    if (buttonElement) observer.observe(buttonElement);

    return () => {
      if (phoneElement) observer.unobserve(phoneElement);
      if (featureListElement) observer.unobserve(featureListElement);
      if (buttonElement) observer.unobserve(buttonElement);
      observer.disconnect();
    };
  }, []);

  return (
    <section id={id} className="app-section">
      <div className="app-content">
        <div className="phone-container" ref={phoneRef}>
          <svg viewBox="0 0 300 600" width="300" height="600" className="phone-svg">
            <rect className="phone-outline" x="20" y="10" width="260" height="580" rx="30" ry="30" fill="none" stroke="white" strokeWidth="4" />
            <rect className="phone-screen" x="30" y="80" width="240" height="420" rx="5" ry="5" fill="none" stroke="white" strokeWidth="2" />
            <circle className="phone-button" cx="150" cy="540" r="20" fill="none" stroke="white" strokeWidth="2" />
            <rect className="phone-speaker" x="120" y="35" width="60" height="10" rx="5" ry="5" fill="white" />
          </svg>
        </div>
        <div className="app-details">
          <h1>Our App</h1>
          <p>Experience the future of AI with our cutting-edge application. Designed to seamlessly integrate into your daily life, our app brings the power of advanced artificial intelligence right to your fingertips.</p>
          <h2>Key Features</h2>
          <ul className="feature-list" ref={featureListRef}>
            {features.map((feature, index) => (
              <li key={index} style={{animationDelay: `${index * 0.1}s`}}>
                <span className="feature-icon">✓</span>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="button-container" ref={buttonRef}>
        <button className="cta-button">LEARN MORE</button>
      </div>
    </section>
  );
};

export default AppSection;