import React from 'react';
import './Home.css';

const Home = ({ id }) => {
  const videoId = "NjEGncridoQ";

  return (
    <section id={id} className="home-section">
      <h1 className="main-headline">Fuehrer AI Project</h1>
      <div className="home-content">
        <div className="home-video-container">
          <iframe
            className="home-video"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="home-buttons">
            <button className="home-button invest-button">Invest</button>
            <button className="home-button learn-more-button">Learn More</button>
          </div>
        </div>
        <div className="home-text-container">
          <h2 className="home-headline">Bridging Past and Present</h2>
          <p className="home-description">
            Welcome to our groundbreaking research project where cutting-edge AI technology meets historical exploration. We are dedicated to translating and transcribing invaluable historical materials, unlocking the secrets of the past with unprecedented accuracy and efficiency.
          </p>
          <p className="home-description">
            Our mission is to bridge the gap between ancient wisdom and modern understanding, making historical knowledge more accessible than ever before. Join us on this exciting journey as we revolutionize the field of historical research and preservation.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
