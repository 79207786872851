import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import AppSection from './components/AppSection';
import Research from './components/Research';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import AcceptPage from './components/AcceptPage';
import Loader from './components/Loader';

const App = () => {
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const termsAccepted = Cookies.get('termsAccepted');
    if (termsAccepted) {
      setAccepted(true);
    }
    setLoading(false);
  }, []);

  const handleAccept = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setAccepted(true);
    }, 3000); // simulate 3 seconds loading
  };

  const handleReject = () => {
    alert('You must accept the rules to enter the site.');
  };

  if (loading) {
    return <Loader />;
  }

  if (!accepted) {
    return <AcceptPage onAccept={handleAccept} onReject={handleReject} />;
  }

  return (
    <div className="container">
      <Header />
      <main>
        <Home id="home" />
        <About id="about" />
        <AppSection id="app" />
        <Research id="research" />
        <FAQ id="faq" />
        <Contact id="contact" />
      </main>
    </div>
  );
};

export default App;