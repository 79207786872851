import React, { useState, useEffect, useCallback } from 'react';
import { FaTelegram, FaTwitter, FaBars, FaTimes, FaEnvelope } from 'react-icons/fa';

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Ensure the header hides when scrolling down and shows when scrolling stops or scrolling up.
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    const scrollingDown = prevScrollPos < currentScrollPos && currentScrollPos > 0;

    setVisible(!scrollingDown);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header className={`header ${visible ? '' : 'hidden'}`}>
      <div className="logo">Fuehrer AI</div>
      <nav className="desktop-nav">
        <a href="#home">Home</a>
        <a href="#about">About Us</a>
        <a href="#app">Our App</a>
        <a href="#research">Research</a>
        <a href="#faq">FAQ</a>
      </nav>
      <div className="social-links">
        <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
          <FaTelegram />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="#contact" aria-label="Contact">
          <FaEnvelope />
        </a>
      </div>
      <div className="menu" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      {isMobileMenuOpen && (
        <nav className="mobile-nav">
          <a href="#home" onClick={toggleMobileMenu}>Home</a>
          <a href="#about" onClick={toggleMobileMenu}>About Us</a>
          <a href="#app" onClick={toggleMobileMenu}>Our App</a>
          <a href="#research" onClick={toggleMobileMenu}>Research</a>
          <a href="#faq" onClick={toggleMobileMenu}>FAQ</a>
          <a href="#contact" onClick={toggleMobileMenu}>Contact</a>
        </nav>
      )}
    </header>
  );
};

export default Header;