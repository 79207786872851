import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import { BadgeCheck, Zap, Brain } from 'lucide-react';
import './Research.css';

const SVGIcon = ({ d }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d={d} />
  </svg>
);

const ResearchItem = ({ icon: Icon, title, items }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
      }}
      className="research-box h-full bg-gradient-to-br from-gray-900 to-gray-800 border-gray-700 shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="card-header flex flex-col items-center">
        <div className="rounded-full bg-primary p-3 mb-4">
          <Icon className="h-6 w-6 text-white" />
        </div>
        <h2 className="text-2xl font-bold text-white mb-2">{title}</h2>
      </div>
      <div className="card-content">
        <ul className="space-y-2">
          {items.map((item, index) => (
            <motion.li
              key={index}
              className="flex items-center text-gray-300"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <BadgeCheck className="h-5 w-5 text-primary mr-2" />
              {item}
            </motion.li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

const Research = ({ id }) => {
  const roadmapData = [
    { 
      year: 2024, 
      icon: <SVGIcon d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />,
      text: 'Foundation', 
      details: [
        'Q1: Project initiation',
        'Q2: First milestone',
        'Q3: Mid-year review',
        'Q4: Year-end evaluation'
      ]
    },
    { 
      year: 2025, 
      icon: <SVGIcon d="M22 12h-4l-3 9L9 3l-3 9H2" />,
      text: 'Growth', 
      details: [
        'Q1: Rapid expansion',
        'Q2: Key partnerships',
        'Q3: Product launch',
        'Q4: Market penetration'
      ]
    },
    { 
      year: 2026, 
      icon: <SVGIcon d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />,
      text: 'Scaling', 
      details: [
        'Global market entry',
        'Advanced AI integration',
        'Expanded research initiatives'
      ]
    },
    { 
      year: 2027, 
      icon: <SVGIcon d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />,
      text: 'Innovation', 
      details: [
        'Breakthrough technologies',
        'AI ethics leadership',
        'Industry standard setting'
      ]
    },
    { 
      year: 2028, 
      icon: <SVGIcon d="M18 8h1a4 4 0 0 1 0 8h-1M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />,
      text: 'Transformation', 
      details: [
        'AI-driven societal impact',
        'Next-gen product suite',
        'Sustainable AI ecosystems'
      ]
    },
  ];

  return (
    <section id={id} className="research-section">
      <motion.h1
        className="main-title"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Research & Roadmap
      </motion.h1>
      <div className="research-content">
        <div className="research-grid">
          <ResearchItem
            icon={Zap}
            title="Our Research"
            items={[
              "Advancing AI technology",
              "Ethical AI development",
              "Human-AI interaction studies",
              "AI safety protocols"
            ]}
          />
          <ResearchItem
            icon={Brain}
            title="Our Objectives"
            items={[
              "Donation wallet funds 100: Kickstart innovation",
              "10,000: Expand research capabilities",
              "1 Million: Achieve major breakthrough"
            ]}
          />
        </div>
        <div className="roadmap-container">
          <motion.h2
            className="text-3xl font-bold text-white mb-8 text-center"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            Project Roadmap
          </motion.h2>
          <div className="roadmap">
            {roadmapData.map((item, index) => (
              <motion.div
                key={index}
                className="roadmap-item"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="roadmap-icon">{item.icon}</div>
                <div className="roadmap-content">
                  <h3>{item.year}</h3>
                  <p>{item.text}</p>
                  <ul className="roadmap-details">
                    {item.details.map((detail, detailIndex) => (
                      <motion.li
                        key={detailIndex}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: (index * 0.1) + (detailIndex * 0.05) }}
                      >
                        {detail}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Research;
