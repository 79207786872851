import React, { useEffect, useRef } from 'react';
import styles from './About.module.css';

const About = ({ id }) => {
  const missionRef = useRef(null);
  const approachRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animate);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Capture current values of refs
    const missionElement = missionRef.current;
    const approachElement = approachRef.current;

    if (missionElement) observer.observe(missionElement);
    if (approachElement) observer.observe(approachElement);

    return () => {
      if (missionElement) observer.unobserve(missionElement);
      if (approachElement) observer.unobserve(approachElement);
      observer.disconnect();
    };
  }, []);

  return (
    <section id={id} className={styles.about}>
      <h1 className={styles.mainHeadline}>About Us</h1>
      
      <div ref={missionRef} className={`${styles.contentSection} ${styles.missionSection}`}>
        <div className={`${styles.textContent} ${styles.slideFromLeft}`}>
          <h2>Our Mission</h2>
          <p>At Fuehrer AI, we are dedicated to bridging the gap between historical documents and modern understanding. Our cutting-edge AI technology allows us to transcribe and translate invaluable historical materials with unprecedented accuracy and efficiency.</p>
        </div>
        <div className={`${styles.imageContent} ${styles.slideFromRight}`}>
          <img src="/assets/images/mission.png" alt="AI analyzing historical documents" />
        </div>
      </div>

      <div className={styles.jaggedDivider}></div>

      <div ref={approachRef} className={`${styles.contentSection} ${styles.approachSection}`}>
        <div className={`${styles.imageContent} ${styles.slideFromLeft}`}>
          <img src="/assets/images/approach.png" alt="Team working on AI models" />
        </div>
        <div className={`${styles.textContent} ${styles.slideFromRight}`}>
          <h2>Our Approach</h2>
          <p>We combine state-of-the-art machine learning algorithms with expert historical knowledge to ensure our translations are not only accurate but also contextually appropriate. Our team of historians and AI specialists work tirelessly to push the boundaries of what's possible in historical research and preservation.</p>
        </div>
      </div>
    </section>
  );
};

export default About;