import React from 'react';
import Cookies from 'js-cookie';
import './AcceptPage.css';

const AcceptPage = ({ onAccept, onReject }) => {
  const handleAccept = () => {
    // Set a cookie that expires in 6 hours
    Cookies.set('termsAccepted', 'true', { expires: 0.25 });
    onAccept();
  };

  return (
    <div className="accept-page">
      <h1>Welcome to Fuehrer AI</h1>
      <p>Please acknowledge the following before proceeding:</p>
      <ul>
        <li><strong>1) </strong> Our content is purely for educational and research purposes.</li>
        <li><strong>2) </strong> Translations are factual with no interpretational bias.</li>
        <li><strong>3) </strong> We do not endorse any of the views contained within the historical content.</li>
      </ul>
      <p>Do you accept these terms and agree to proceed?</p>
      <div className="buttons">
        <button onClick={handleAccept}>Yes</button>
        <button onClick={() => window.open('https://www.reddit.com', '_blank')}>No</button>
      </div>
    </div>
  );
};

export default AcceptPage;