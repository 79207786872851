import React, { useState } from 'react';
import './FAQ.css';

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <div className={`faq-item ${isOpen ? 'faq-open' : ''}`} onClick={onClick}>
    <h2 className="faq-question">{question}</h2>
    {isOpen && (
      <p className="faq-answer">{answer}</p>
    )}
  </div>
);

const FAQ = ({ id }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    { question: "Question 1", answer: "Answer 1" },
    { question: "Question 2", answer: "Answer 2" },
    { question: "Question 3", answer: "Answer 3" },
    { question: "Question 4", answer: "Answer 4" },
    { question: "Question 5", answer: "Answer 5" },
    { question: "Question 6", answer: "Answer 6" },
    { question: "Question 7", answer: "Answer 7" },
  ];

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id={id} className="faq-section">
      <div className="faq-content">
        <h1 className="faq-title">FAQ</h1>
        <div className="faq-container">
          {faqData.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndex === index}
              onClick={() => handleClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;