import React, { useState, useEffect } from 'react';
import styles from './Contact.module.css';

const Contact = ({ id }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 3000);
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animate);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const elements = document.querySelectorAll(`.${styles.animateOnScroll}`);
    elements.forEach(el => observer.observe(el));

    return () => elements.forEach(el => observer.unobserve(el));
  }, []);

  return (
    <section id={id} className={styles.contact}>
      <h1 className={styles.title}>Contact Us</h1>
      <div className={styles.contactContent}>
        <form onSubmit={handleSubmit} className={`${styles.contactForm} ${styles.animateOnScroll}`}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">Send Message</button>
          {isSubmitted && <p className={styles.submitMessage}>Message sent successfully!</p>}
        </form>
        <div className={`${styles.contactInfo} ${styles.animateOnScroll}`}>
          <h2>Get in Touch</h2>
          <p>Feel free to reach out to us through any of these channels:</p>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              <a href="mailto:info@fuehrerai.com">info@fuehrerai.com</a>
            </li>
            <li>
              <i className="fab fa-telegram"></i>
              <a href="https://t.me/fuehrerai" target="_blank" rel="noopener noreferrer">@fuehrerai</a>
            </li>
            <li>
              <i className="fab fa-twitter"></i>
              <a href="https://twitter.com/fuehrerai" target="_blank" rel="noopener noreferrer">@fuehrerai</a>
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <span>Berlin, Germany</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contact;